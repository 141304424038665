import { ThemeProvider } from "./ThemeProvider";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { useMemo, useState } from "react";
import { UnsafeBurnerWalletAdapter } from "@solana/wallet-adapter-wallets";
import App from "./App";


export function ProivdersWrapper() {
  const [endpoint, setEndpoint] = useState<string>('https://rpc.helius.xyz/?api-key=53c6cb3b-bc8b-49de-982f-ffda117197c8');
  const wallets = useMemo(() => [new UnsafeBurnerWalletAdapter()], []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={true}>
        <ThemeProvider>
          <App endpoint={endpoint} setEndpoint={setEndpoint} />
        </ThemeProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
