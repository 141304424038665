import { Text, Button, createStyles, Group, Avatar, Container, Box, ActionIcon, Modal, TextInput, Stack, SegmentedControl } from "@mantine/core";
import { AppShell, Header } from '@mantine/core';
import { Menu } from '@mantine/core';
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { PrettyClearingHouse, SDK, Wallet } from "@solworks/sujiko-sdk";
import {
  createBrowserRouter,
  RouterProvider} from "react-router-dom";
import { MarketHomeView } from "./MarketHomeView";
import { MarketDetailView } from "./MarketDetailView";
import { IconEdit, IconNewSection, IconRefresh } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";

const endpoints = [
  {
    name: 'mainnet-beta',
    url: 'https://rpc.helius.xyz/?api-key=69569714-8b0d-4666-91a9-d4e70979894f',
    network: 'mainnet-beta',
  }, 
  {
    name: 'devnet',
    url: 'https://devnet.helius-rpc.com/?api-key=69569714-8b0d-4666-91a9-d4e70979894f',
    network: 'devnet',
  }
]

export const useStyles = createStyles((theme) => ({
  appShell: {
    backgroundColor: 'rgb(3, 7, 17)',
    paddingBottom: 64
  },
  navbar: {
    backgroundColor: 'rgb(3, 7, 17)',
    borderRight: '1px solid rgb(29, 40, 58)',
  },
  header: {
    backgroundColor: 'rgb(3, 7, 17)',
    borderBottom: '1px solid rgb(29, 40, 58)',
  },
  dropdown: {
    backgroundColor: 'rgb(3, 7, 17)',
    color: '#fff',
    border: '1px solid rgb(29, 40, 58)',
  }
}));

export default function App({
  endpoint,
  setEndpoint,
}: {
  endpoint: string,
  setEndpoint: (endpoint: string) => void,
}) {
  // hooks
  const { classes } = useStyles();
  const { 
    connect, 
    select, 
    wallets, 
    connected, 
    publicKey, 
    disconnect,
    wallet,
  } = useWallet();
  const { connection } = useConnection();
  
  // state
  const [opened, { open, close }] = useDisclosure(false);
  const [sdk, setSdk] = useState<SDK | null>(SDK.buildWithNoWallet({connection}));
  const [clearingHouse, setClearingHouse] = useState<PrettyClearingHouse | null>(null);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MarketHomeView ch={clearingHouse} />, 
    },
    {
      path: "/market/:marketId",
      element: <MarketDetailView ch={clearingHouse} sdk={sdk} />,
    },
  ]);

  // effects
  useEffect(() => {
    async function init() {
      if (connected && wallet) {
        const sdk = await SDK.build({
          connection,
          wallet: (wallet.adapter as Wallet),
          isDevnet: endpoint.includes('devnet')
        });
        setSdk(sdk);
      } else {
        const sdk = SDK.buildWithNoWallet({
          connection,
          isDevnet: endpoint.includes('devnet')
        });
        setSdk(sdk);
      }
    }

    init();
  }, [connected, wallet, connection, endpoint]);

  useEffect(() => {
    async function getMarkets() {
      if (sdk) {
        const ch = await sdk.getClearingHouse();
        const marketsList = await sdk.getMarketsList();
        const pch = sdk.prettyFormat(ch, marketsList);
        setClearingHouse(pch);
        setRefreshing(false);
      }
    }

    getMarkets();
  }, [sdk, refreshing]);
  
  return (
    <AppShell
      padding={0}
      header={
        <>
          <CreateNewCollectionModal opened={opened} close={close} />
          <Header height={57} p="xs" className={classes.header}>
            <Group position='apart'>
              <Box component="a" href="/" style={{ color: '#fff', textDecoration: 'none' }}>
                <Text size="xl" style={{ color: '#7f8ea3', paddingLeft: 18 }}>🍣 SUJIKO</Text>
              </Box>
              <Group>
                {/* <ActionIcon>
                  <IconNewSection size={18} onClick={() => open()} />
                </ActionIcon> */}
                <ActionIcon loading={refreshing}>
                  <IconRefresh size={18} onClick={() => setRefreshing(!refreshing)} />
                </ActionIcon>
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button 
                      variant='white' 
                      color="dark"
                    >
                      {connected ? `Connected (${publicKey?.toBase58().slice(0,4)}…${publicKey?.toBase58().slice(-4)})`: "Connect wallet"}
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown className={classes.dropdown}>
                    <Menu.Label>Network</Menu.Label>
                    {endpoints.map((endpoint) => {
                      return (
                        <Menu.Item 
                          key={endpoint.url} 
                          onClick={() => {
                            setEndpoint(endpoint.url);
                          }}
                        >
                          {endpoint.name}
                        </Menu.Item>
                      );
                    })}
                    {!connected && <Menu.Label>Wallets</Menu.Label>}
                    {!connected && wallets.map((wallet) => {
                      return (
                        <Menu.Item 
                          key={wallet.adapter.name} 
                          onClick={() => {
                            select(wallet.adapter.name);
                            connect();
                          }}
                          icon={<Avatar src={wallet.adapter.icon} size='14px' radius={100} />}
                        >
                          {wallet.adapter.name}
                        </Menu.Item>
                      );
                    })}
                    {connected && <Menu.Label>Manage</Menu.Label>}
                    {connected && <Menu.Item icon={
                      <IconEdit size={18} />
                    } onClick={() => disconnect()}>Disconnect</Menu.Item>}
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Group>
          </Header>
        </>
      }
      className={classes.appShell}
    >
      <RouterProvider router={router} />
    </AppShell>
  );
}

// create number formatter for USD
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// create number formatter for 2 decimal places
export const formatter2 = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});

const CreateNewCollectionModal = ({ opened, close }: { opened: boolean, close: () => void }) => {
  const [name, setName] = useState<string>('');
  const [collectionMint, setCollectionMint] = useState<string>('');
  const [openseaSlug, setOpenseaSlug] = useState<string>('');
  const [chain, setChain] = useState('bitcoin');
  const [helloMoonId, setHelloMoonId] = useState<string>('temp');

  return (
    <Modal opened={opened} onClose={close} title="Create new collection">
      <Stack>
        <TextInput label='Name' withAsterisk value={name} onChange={(event) => setName(event.currentTarget.value)} />
        <TextInput label='Collection mint' withAsterisk value={collectionMint} onChange={(event) => setCollectionMint(event.currentTarget.value)} />
        <TextInput label='Opensea slug' withAsterisk value={openseaSlug} onChange={(event) => setOpenseaSlug(event.currentTarget.value)} />
        <SegmentedControl
          data={[
            { label: 'Bitcoin', value: 'bitcoin' },
            { label: 'Ethereum', value: 'ethereum' },
            { label: 'Solana', value: 'solana' },
          ]}
          value={chain}
          onChange={(value) => setChain(value)}
        />
        <TextInput label='Hello Moon ID' value={helloMoonId} onChange={(event) => setHelloMoonId(event.currentTarget.value)} />
        <Button variant="white" color="dark" onClick={async () => {
          const data = {
            name,
            collectionId: collectionMint,
            openseaCollectionName: openseaSlug,
            network: chain,
            helloMoonCollectionId: helloMoonId,
          };
          console.log(data);

          // validate data
          if (name === '' || collectionMint === '' || openseaSlug === '' || chain === '' || helloMoonId === '') {
            return;
          }

          // create collection
          try {
            // const response = await createCollection(data);
            // console.log(response);
            // notifications.show({
            //   message: 'Collection created',
            //   variant: 'success',
            // });
            close();
          } catch (error: any) {
            console.log(error);
            notifications.show({
              title: 'Error creating collection',
              variant: 'error',
              message: error.message,
            });
          }
        }}>Create</Button>
      </Stack>
    </Modal>
  );
}

// async function createCollection(data: any) {
//   const response = await fetch('https://mqzuv8qnhd.execute-api.us-east-1.amazonaws.com/collection/register', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(data),
//   });

//   return response.json();
// }