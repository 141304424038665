import { Text, Stack, Title, Space, Group, Table, ActionIcon } from "@mantine/core";
import { Breadcrumbs, Anchor } from '@mantine/core';
import { PrettyClearingHouse } from "@solworks/sujiko-sdk";
import { useNavigate } from "react-router-dom";
import { formatter2 } from "./App";

export function MarketHomeView({ ch }: { ch: PrettyClearingHouse | null; }) {
  const navigate = useNavigate();
  return <Stack align="left" spacing={0} style={{
    height: "100vh",
    width: "100%",
    background: "#030712",
    margin: 0,
    padding: 32,
    marginBottom: 300,
  }}>
    <Breadcrumbs separator="→" mt="xs">
      <Anchor color='#7f8ea3' onClick={() => {
        navigate('/');
      }}>Markets</Anchor>
    </Breadcrumbs>
    <Space h={12} />
    <Title order={1}>Markets</Title>
    <Space h={4} />
    <Text size="lg" style={{ color: '#7f8ea3' }}>
      Select a market to view its details
    </Text>
    <Space h={32} />
    <Group spacing={0} style={{
      border: '1px solid rgb(29, 40, 58)',
      borderRadius: '0.5rem'
    }}>
      <Table highlightOnHover>
        <thead>
          <tr>
            <th style={{ minWidth: 120 }}><Text size="md" style={{ color: '#7f8ea3' }}>Name</Text></th>
            <th style={{ minWidth: 120 }}><Text size="md" style={{ color: '#7f8ea3' }}>Funds</Text></th>
            <th><Text size="md" style={{ color: '#7f8ea3' }}>Stats</Text></th>
            <th><Text size="md" style={{ color: '#7f8ea3' }}>Price</Text></th>
            <th><Text size="md" style={{ color: '#7f8ea3' }}>Spread</Text></th>
            <th><Text size="md" style={{ color: '#7f8ea3' }}>Margin Ratios</Text></th>
            <th><Text size="md" style={{ color: '#7f8ea3' }}>Market skew</Text></th>
            <th><Text size="md" style={{ color: '#7f8ea3' }}>Peg Multiplier</Text></th>
            <th><Text size="md" style={{ color: '#7f8ea3' }}>Sqrt K</Text></th>
            <th><Text size="md" style={{ color: '#7f8ea3' }}></Text></th>
          </tr>
        </thead>
        <tbody>
          {ch && ch?.markets.map((market) => {
            return (
              <tr key={market.name} onClick={() => {
                navigate(`/market/${market.index}`);
              }} style={{ cursor: 'pointer' }}>
                <td>
                  <Stack spacing={4}>
                    <Text size="sm" style={{ color: '#fff' }}>{market.name}</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Index: {market.index}</Text>
                  </Stack>
                </td>
                <td>
                  <Stack spacing={4}>
                    <Text size="12px" style={{ color: '#fff' }}>PNL: ${market.pnlFundBalance.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Funding: ${market.fundingPaymentsFundBalance.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                  </Stack>
                </td>
                <td>
                  <Stack spacing={4}>
                    <Text size="12px" style={{ color: '#fff' }}>OI: ${(market.amm.openInterest * market.amm.oraclePrice).toLocaleString('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Positions: {`${market.positionsCount}`}</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Next funding: {`${(market.amm.nextEstimatedFundingRate * 100).toFixed(5)}%`}</Text>
                  </Stack>
                </td>
                <td>
                  <Stack spacing={4}>
                    <Text size="12px" style={{ color: '#fff' }}>Mark: ${market.amm.currentPrice.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Oracle: ${market.amm.oraclePrice.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Premium: {(market.amm.oraclePrice - market.amm.currentPrice).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} ({((market.amm.oraclePrice - market.amm.currentPrice) / market.amm.currentPrice * 100).toLocaleString('en-US', { maximumFractionDigits: 1, minimumFractionDigits: 1 })}%)</Text>
                  </Stack>
                </td>
                <td>
                  <Stack spacing={4}>
                    <Text size="12px" style={{ color: '#fff' }}>Base: {`${(market.amm.baseSpread * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 })}%`}</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Max: {`${(market.amm.maxSpread * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 })}%`}</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Long: {`${(market.amm.longSpread * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 })}%`}</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Short: {`${(market.amm.shortSpread * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 })}%`}</Text>
                  </Stack>
                </td>
                <td>
                  <Stack spacing={4}>
                    <Text size="12px" style={{ color: '#fff' }}>IMR: {formatter2.format(1/market.initialMarginRatio)}x</Text>
                    <Text size="12px" style={{ color: '#fff' }}>MMR: {formatter2.format(1/market.maintenanceMarginRatio)}x</Text>
                  </Stack>
                </td>
                <td>
                  <Stack spacing={4}>
                    <Text size="12px" style={{ color: '#fff' }}>Long: {(market.amm.baseAssetAmountLong/1e8 / market.amm.openInterest * 100).toFixed(1)}%</Text>
                    <Text size="12px" style={{ color: '#fff' }}>Short: {(Math.abs(market.amm.baseAssetAmountShort/1e8 / market.amm.openInterest * 100).toFixed(1))}%</Text>
                  </Stack>
                </td>
                <td><Text size="sm" style={{ color: '#fff' }}>{market.amm.kPegMultiplier.toFixed(5)}</Text></td>
                <td><Text size="sm" style={{ color: '#fff' }}>{market.amm.sqrtK.toFixed(2)}</Text></td>

                <td>
                  <ActionIcon variant='outline' size='lg' onClick={() => {
                    navigate(`/market/${market.index}`);
                  }}>
                    <Text size="sm" style={{ color: '#fff' }}>Edit</Text>
                  </ActionIcon>
                </td>
              </tr>
            );
          })}
          {!ch && <tr>
            <td colSpan={9}><Text size="sm" style={{ color: '#fff' }}>Loading...</Text></td>
          </tr>}
        </tbody>
      </Table>
    </Group>
  </Stack>;
}
