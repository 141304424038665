import { Text, Stack, Title, Space, Group, Grid, ActionIcon, Modal, Button, NumberInput, Popover, TextInput } from "@mantine/core";
import { Breadcrumbs, Anchor } from '@mantine/core';
import { Decimal, MarketStatus, MarketType, OracleType, PrettyClearingHouse, SDK } from "@solworks/sujiko-sdk";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStyles, formatter2, formatter } from "./App";
import { IconEdit } from "@tabler/icons-react";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { ContractTier } from "@solworks/sujiko-sdk/dist/enums/ContractTier";

export function MarketDetailView({ ch, sdk }: { ch: PrettyClearingHouse | null; sdk: SDK | null }) {
  const { classes } = useStyles();
  const { marketId } = useParams();
  const [imr, setImr] = useState<string | ''>('1.5');
  const [mmr, setMmr] = useState<string | ''>('1.5');
  const [maxOrderSize, setMaxOrderSize] = useState<string | ''>('1.0');
  const [minOrderSize, setMinOrderSize] = useState<string | ''>('0.1');
  const [baseSpread, setBaseSpread] = useState<string | ''>('0.25');
  const [maxSpread, setMaxSpread] = useState<string | ''>('25');
  const [maxBaseAssetReserve, setMaxBaseAssetReserve] = useState<string | ''>('1000');
  const market = ch?.markets.find((market) => market.index === parseFloat(marketId || ''));
  const navigate = useNavigate();

  if (!market) {
    return <div>Market not found</div>;
  }

  return (
    <Stack align="left" spacing={0} style={{
      height: "100%",
      width: "100%",
      background: "#030712",
      margin: 0,
      padding: 32
    }}>
      <Breadcrumbs separator="→" mt="xs">
        <Anchor color='#7f8ea3' onClick={() => {
          navigate('/');
        }}>Markets</Anchor>
        <Anchor onClick={() => {
          navigate(`/market/${market.index}`);
        }} color='#7f8ea3'>{market.name}</Anchor>
      </Breadcrumbs>
      <Space h={12} />
      <Title order={1}>{market.name}</Title>
      <Space h={32} />
      <Group grow>
        <Stack spacing={0} style={{
          border: '1px solid rgb(29, 40, 58)',
          borderRadius: '0.5rem',
          padding: 24
        }}>
          <Text size='md'>Open Interest</Text>
          <Space h={6} />
          <Title order={2}>{formatter2.format(market.amm.openInterest)}</Title>
          <Text size='md' c='dimmed'>~{formatter.format(market.amm.openInterest * market.amm.currentPrice)}</Text>
        </Stack>
        <Stack spacing={0} style={{
          border: '1px solid rgb(29, 40, 58)',
          borderRadius: '0.5rem',
          padding: 24
        }}>
          <Text size='md'>PNL Fund Balance</Text>
          <Space h={6} />
          <Title order={2}>{formatter.format(market.pnlFundBalance)}</Title>
          <Text size='md' c='dimmed'>Available for PNL</Text>
        </Stack>
        <Stack spacing={0} style={{
          border: '1px solid rgb(29, 40, 58)',
          borderRadius: '0.5rem',
          padding: 24
        }}>
          <Text size='md'>Mark (oracle) price</Text>
          <Space h={6} />
          <Title order={2}>{formatter.format(market.amm.currentPrice)}</Title>
          <Text size='md' c='dimmed'>{formatter.format(market.amm.oraclePrice)}</Text>
        </Stack>
        <Stack spacing={0} style={{
          border: '1px solid rgb(29, 40, 58)',
          borderRadius: '0.5rem',
          padding: 24
        }}>
          <Text size='md'>Position Count</Text>
          <Space h={6} />
          <Title order={2}>{`${market.positionsCount}`}</Title>
          <Text size='md' c='dimmed'>Positions open</Text>
        </Stack>
      </Group>

      <Space h={32} />

      <Grid>
        <Grid.Col span={6}>
          <Stack spacing={0} style={{
            border: '1px solid rgb(29, 40, 58)',
            borderRadius: '0.5rem',
            padding: '20px 24px'
          }}>
            <Title order={4}>Basic Details</Title>
            <Text size='md' c='dimmed'>See core details about this market</Text>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}> Name</Title>
                <Text size='md' c='dimmed'>{market.name}</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Type</Title>
                <Text size='md' c='dimmed'>{MarketType[market.marketType]}</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Status</Title>
                <Text size='md' c='dimmed'>{MarketStatus[market.status]}</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Oracle</Title>
                <Group>
                <Text size='md' c='dimmed'>
                    {OracleType[market.amm.oracleType]}
                  </Text>
                  <Text size='sm' c='dimmed' style={{ display: 'block', textDecoration: 'underline', cursor: 'pointer' }} onClick={async () => {
                    if (sdk) {
                      try {
                        const sig = await sdk.updateSwitchboardOraclePrice(market.index, true);
                        console.log(sig);
                        const url = `https://solana.fm/tx/${sig}?cluster=devnet-qn1`
                        notifications.show({
                          message: 'Successfully updated oracle price' + <a href={url} target="_blank" rel="noreferrer">See tx</a>,
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    }
                  }}>
                    Update now
                  </Text>
                </Group>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Feed</Title>
                <Text size='md' c='dimmed'><a href={`https://app.switchboard.xyz/solana/devnet/feed/${market.amm.oraclePubkey}`} target="_blank" rel="noreferrer">{market.amm.oraclePubkey}</a></Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Contract Type</Title>
                <Text size='md' c='dimmed'>{ContractTier[market.contractTier].toUpperCase()}</Text>
              </Stack>
            </Group>

          </Stack>
          <Space h={16} />
          <Stack spacing={0} style={{
            border: '1px solid rgb(29, 40, 58)',
            borderRadius: '0.5rem',
            padding: '20px 24px'
          }}>
            <Title order={4}>Margin</Title>
            <Text size='md' c='dimmed'>See margin ratios for this market</Text>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Group spacing={4}>
                  <Title order={5}>IMR (initial margin ratio)</Title>
                  <Popover width={200} position="bottom" withArrow>
                    <Popover.Target>
                      <ActionIcon>
                        <IconEdit size={16} />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Stack>
                        <TextInput value={imr} onChange={(val) => {setImr(val.currentTarget.value)}} min={0} max={100} step={0.5} />
                        <Button onClick={async () => {
                          try {
                            const imrNum = Number(imr);
                            if (isNaN(imrNum)) {
                              throw new Error('NaN');
                            }
                            const imrPercent = 1/imrNum * 100;
                            console.log(`Setting IMR to ${imrPercent}%`);

                            if (sdk) {
                              const sig = await sdk.updateInitialMarginRatio(market.index, Decimal.fromPercent(imrPercent));
                              console.log(sig);
                              const url = `https://solana.fm/tx/${sig}?cluster=devnet-qn1`
                              notifications.show({
                                title: 'Successfully set IMR',
                                message: <a href={url} target="_blank" rel="noreferrer">See tx</a>,
                              });
                            }
                          } catch (e) {
                            console.log(e)
                          }
                        }}>Set</Button>
                      </Stack>
                    </Popover.Dropdown> 
                  </Popover>
                </Group>
                <Text size='md' c='dimmed'>{formatter2.format(1/market.initialMarginRatio)}x ({market.initialMarginRatio * 100}%)</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Group spacing={4}>
                  <Title order={5}>MMR (maintenance/min margin ratio)</Title>
                  <Popover width={200} position="bottom" withArrow>
                    <Popover.Target>
                      <ActionIcon>
                        <IconEdit size={16} />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Stack>
                        <TextInput value={mmr} onChange={(val) => {setMmr(val.currentTarget.value)}} min={0} max={100} step={0.5} />
                        <Button onClick={async () => {
                          try {
                            const mmrNum = Number(mmr);
                            console.log(mmrNum);
                            if (isNaN(mmrNum)) {
                              throw new Error('NaN');
                            }
                            const mmrPercent = 1/mmrNum * 100;
                            console.log(`Setting MMR to ${mmrPercent}%`);

                            if (sdk) {
                              const sig = await sdk.updateMaintenanceMarginRatio(market.index, Decimal.fromPercent(mmrPercent));
                              console.log(sig);
                              const url = `https://solana.fm/tx/${sig}?cluster=devnet-qn1`
                              notifications.show({
                                title: 'Successfully set MMR',
                                message: <a href={url} target="_blank" rel="noreferrer">See tx</a>,
                              });
                            }
                          } catch (e) {
                            console.log(e)
                          }
                        }}>Set</Button>
                      </Stack>
                    </Popover.Dropdown> 
                  </Popover>
                </Group>
                <Text size='md' c='dimmed'>{formatter2.format(1/market.maintenanceMarginRatio)}x ({market.maintenanceMarginRatio * 100}%)</Text>
              </Stack>
            </Group>
          </Stack>
        </Grid.Col>
        <Grid.Col span={6}>
          <Stack spacing={0} style={{
            border: '1px solid rgb(29, 40, 58)',
            borderRadius: '0.5rem',
            padding: '20px 24px'
          }}>
            <Title order={4}>Configuration</Title>
            <Text size='md' c='dimmed'>See configuration for this market</Text>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Order step size</Title>
                <Text size='md' c='dimmed'>{market.amm.orderStepSize}</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Order price tick size</Title>
                <Text size='md' c='dimmed'>{market.amm.orderTickSize}</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Maximum skew</Title>
                <Text size='md' c='dimmed'>{formatter2.format(market.maximumSkew * 100)}%</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Maximum slippage percent</Title>
                <Text size='md' c='dimmed'>{formatter2.format(market.amm.maximumSlippagePercent * 100)}%</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Maximum open interest</Title>
                <Text size='md' c='dimmed'>{market.amm.maximumOpenInterest} contracts ({formatter.format(market.amm.maximumOpenInterest * market.amm.currentPrice)})</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Maximum position size</Title>
                <Text size='md' c='dimmed'>{market.amm.maximumPositionSize} contracts ({formatter.format(market.amm.maximumPositionSize * market.amm.currentPrice)})</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Group spacing={4}>
                <Title order={5}>Minimum order size</Title>
                  <Popover width={200} position="bottom" withArrow>
                    <Popover.Target>
                      <ActionIcon>
                        <IconEdit size={16} />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Stack>
                        <TextInput value={minOrderSize} onChange={(val) => {setMinOrderSize(val.currentTarget.value)}} min={0.0001} max={1} step={0.0001} />
                        <Button onClick={async () => {
                          try {
                            const minOrderSizeNum = Number(minOrderSize);
                            if (isNaN(minOrderSizeNum)) {
                              throw new Error('NaN');
                            }
                            console.log(`Setting min order size to ${minOrderSizeNum}`);

                            if (sdk) {
                              const sig = await sdk.updateMinimumOrderSize(market.index, Decimal.fromSize(minOrderSizeNum));
                              console.log(sig);
                              const url = `https://solana.fm/tx/${sig}?cluster=devnet-qn1`
                              notifications.show({
                                title: 'Successfully set min order size',
                                message: <a href={url} target="_blank" rel="noreferrer">See tx</a>,
                              });
                            }
                          } catch (e) {
                            console.log(e)
                          }
                        }}>Set</Button>
                      </Stack>
                    </Popover.Dropdown> 
                  </Popover>
                </Group>
                <Text size='md' c='dimmed'>{market.amm.minimumOrderSize} contracts ({formatter.format(market.amm.minimumOrderSize * market.amm.currentPrice)})</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Group spacing={4}>
                <Title order={5}>Maximum order size</Title>
                  <Popover width={200} position="bottom" withArrow>
                    <Popover.Target>
                      <ActionIcon>
                        <IconEdit size={16} />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Stack>
                        <TextInput value={maxOrderSize} onChange={(val) => {setMaxOrderSize(val.currentTarget.value)}} min={0.1} max={1000} step={0.1} />
                        <Button onClick={async () => {
                          try {
                            const maxOrderSizeNum = Number(maxOrderSize);
                            if (isNaN(maxOrderSizeNum)) {
                              throw new Error('NaN');
                            }
                            console.log(`Setting max order size to ${maxOrderSizeNum}`);

                            if (sdk) {
                              const sig = await sdk.updateMaximumOrderSize(market.index, Decimal.fromSize(maxOrderSizeNum));
                              console.log(sig);
                              const url = `https://solana.fm/tx/${sig}?cluster=devnet-qn1`
                              notifications.show({
                                title: 'Successfully set max order size',
                                message: <a href={url} target="_blank" rel="noreferrer">See tx</a>,
                              });
                            }
                          } catch (e) {
                            console.log(e)
                          }
                        }}>Set</Button>
                      </Stack>
                    </Popover.Dropdown> 
                  </Popover>
                </Group>
                <Text size='md' c='dimmed'>{market.amm.maximumOrderSize} contracts ({formatter.format(market.amm.maximumOrderSize * market.amm.currentPrice)})</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Group spacing={4}>
                  <Title order={5}>Base asset amount long</Title>
                  <Text size='md' c='dimmed'>{market.amm.baseAssetAmountLong/1e8}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Base asset amount short</Title>
                  <Text size='md' c='dimmed'>{market.amm.baseAssetAmountShort/1e8}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Base asset with vAMM</Title>
                  <Text size='md' c='dimmed'>{market.amm.baseAssetWithVamm/1e8}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Quote asset amount</Title>
                  <Text size='md' c='dimmed'>{market.amm.quoteAssetAmount/1e8}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Quote break even long</Title>
                  <Text size='md' c='dimmed'>{market.amm.quoteBreakEvenLong/1e8}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Quote break even short</Title>
                  <Text size='md' c='dimmed'>{market.amm.quoteBreakEvenShort/1e8}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Quote entry amount long</Title>
                  <Text size='md' c='dimmed'>{market.amm.quoteEntryAmountLong/1e8}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Quote entry amount short</Title>
                  <Text size='md' c='dimmed'>{market.amm.quoteEntryAmountShort/1e8}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Bid base asset reserve</Title>
                  <Text size='md' c='dimmed'>{market.amm.bidBaseAssetReserve}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Bid quote asset reserve</Title>
                  <Text size='md' c='dimmed'>{market.amm.bidQuoteAssetReserve}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Ask base asset reserve</Title>
                  <Text size='md' c='dimmed'>{market.amm.askBaseAssetReserve}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Ask quote asset reserve</Title>
                  <Text size='md' c='dimmed'>{market.amm.askQuoteAssetReserve}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Min base asset reserve</Title>
                  <Text size='md' c='dimmed'>{market.amm.minBaseAssetReserve}</Text>
                </Group>
                <Group spacing={4}>
                  <Title order={5}>Max base asset reserve</Title>
                  <Popover width={200} position="bottom" withArrow>
                    <Popover.Target>
                      <ActionIcon>
                        <IconEdit size={16} />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Stack>
                        <TextInput value={maxBaseAssetReserve} onChange={(val) => {setMaxBaseAssetReserve(val.currentTarget.value)}} min={0.1} max={100_000} step={0.1} />
                        <Button onClick={async () => {
                          try {
                            const maxBaseAssetReserveNum = Number(maxBaseAssetReserve);
                            if (isNaN(maxBaseAssetReserveNum)) {
                              throw new Error('NaN');
                            }
                            console.log(`Setting max base asset reserve to ${maxBaseAssetReserveNum}`);

                            if (sdk) {
                              const sig = await sdk.updateMaximumBaseReserve(market.index, Decimal.fromSize(maxBaseAssetReserveNum));
                              console.log(sig);
                              const url = `https://solana.fm/tx/${sig}?cluster=devnet-qn1`
                              notifications.show({
                                title: 'Successfully set max base asset reserve',
                                message: <a href={url} target="_blank" rel="noreferrer">See tx</a>,
                              });
                            }
                          } catch (e) {
                            console.log(e)
                          }
                        }}>Set</Button>
                      </Stack>
                    </Popover.Dropdown> 
                  </Popover>
                  <Text size='md' c='dimmed'>{market.amm.maxBaseAssetReserve}</Text>
                </Group>

                <Space h={12} />

                <Stack spacing={4}>
                  <Title order={5}>OI</Title>
                  <Text size='md' c='dimmed'>${(market.amm.openInterest * market.amm.currentPrice).toFixed(2)}</Text>
                </Stack>
                <Stack spacing={4}>
                  <Title order={5}>Long OI</Title>
                  <Text size='md' c='dimmed'>${(market.amm.baseAssetAmountLong/1e8 * market.amm.currentPrice).toFixed(2)} ({(market.amm.baseAssetAmountLong/1e8 / market.amm.openInterest * 100).toFixed(1)}%)</Text>
                </Stack>
                <Stack spacing={4}>
                  <Title order={5}>Short OI</Title>
                  <Text size='md' c='dimmed'>${(Math.abs(market.amm.baseAssetAmountShort * market.amm.currentPrice)/1e8).toFixed(2)} ({(Math.abs(market.amm.baseAssetAmountShort/1e8 / market.amm.openInterest * 100).toFixed(1))}%)</Text>
                </Stack>
                <Stack spacing={4}>
                  <Title order={5}>Market skew</Title>
                  <Text size='md' c='dimmed'>{(market.amm.baseAssetAmountLong/1e8 / market.amm.openInterest * 100).toFixed(1)}% / {(Math.abs(market.amm.baseAssetAmountShort/1e8 / market.amm.openInterest * 100).toFixed(1))}%</Text>
                </Stack>
              </Stack>
            </Group>
          </Stack>
          <Space h={16} />
          <Stack spacing={0} style={{
            border: '1px solid rgb(29, 40, 58)',
            borderRadius: '0.5rem',
            padding: '20px 24px'
          }}>
            <Title order={4}>Spreads</Title>
            <Text size='md' c='dimmed'>See spreads for this market</Text>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Group spacing={4}>
                  <Title order={5}>Base spread</Title>
                  <Popover width={200} position="bottom" withArrow>
                    <Popover.Target>
                      <ActionIcon>
                        <IconEdit size={16} />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Stack>
                        <TextInput value={baseSpread} onChange={(val) => {setBaseSpread(val.currentTarget.value)}} min={0} max={100} step={0.01} />
                        <Button onClick={async () => {
                          try {
                            const baseSpreadNum = Number(baseSpread);
                            if (isNaN(baseSpreadNum)) {
                              notifications.show({
                                title: 'Invalid base spread',
                                message: 'Base spread must be a number',
                              });
                              throw new Error('NaN');
                            }
                            const baseSpreadPercent = Decimal.fromPercent(baseSpreadNum);
                            console.log(`Setting base spread to ${baseSpreadPercent}%`);

                            if (sdk) {
                              notifications.show({
                                title: 'Setting base spread',
                                message: 'Please wait...',
                              })
                              const sig = await sdk.updateBaseSpread(market.index, baseSpreadPercent);
                              console.log(sig);
                              const url = `https://solana.fm/tx/${sig}`
                              notifications.show({
                                title: 'Successfully set base spread',
                                message: <a href={url} target="_blank" rel="noreferrer">See tx</a>,
                              });
                            }
                          } catch (e) {
                            console.log(e)
                          }
                        }}>Set</Button>
                      </Stack>
                    </Popover.Dropdown> 
                  </Popover>
                </Group>
                <Text size='md' c='dimmed'>{market.amm.baseSpread * 100}%</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Group spacing={4}>
                  <Title order={5}>Max spread</Title>
                  <Popover width={200} position="bottom" withArrow>
                    <Popover.Target>
                      <ActionIcon>
                        <IconEdit size={16} />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Stack>
                        <TextInput value={maxSpread} onChange={(val) => {setMaxSpread(val.currentTarget.value)}} min={0} max={100} step={0.01} />
                        <Button onClick={async () => {
                          try {
                            const maxSpreadNum = Number(maxSpread);
                            if (isNaN(maxSpreadNum)) {
                              throw new Error('NaN');
                            }
                            const maxSpreadPercent = Decimal.fromPercent(maxSpreadNum);
                            console.log(`Setting max spread to ${JSON.stringify(maxSpreadPercent)}%`);

                            if (sdk) {
                              const sig = await sdk.updateMaxSpread(market.index, maxSpreadPercent);
                              console.log(sig);
                              const url = `https://solana.fm/tx/${sig}?cluster=devnet-qn1`
                              notifications.show({
                                title: 'Successfully set max spread',
                                message: <a href={url} target="_blank" rel="noreferrer">See tx</a>,
                              });
                            }
                          } catch (e) {
                            console.log(e)
                          }
                        }}>Set</Button>
                      </Stack>
                    </Popover.Dropdown> 
                  </Popover>
                </Group>
                <Text size='md' c='dimmed'>{market.amm.maxSpread * 100}%</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Long spread</Title>
                <Text size='md' c='dimmed'>{market.amm.longSpread * 100}%</Text>
              </Stack>
            </Group>

            <Space h={12} />
            <Group grow>
              <Stack spacing={0}>
                <Title order={5}>Short spread</Title>
                <Text size='md' c='dimmed'>{market.amm.shortSpread * 100}%</Text>
              </Stack>
            </Group>
          </Stack>
        </Grid.Col>
      </Grid>
    </Stack>
  );
}